<template>
    <div class="container">
        <form action="/">
            <van-search
                    v-model="keyword"
                    placeholder="请输入房间号、名称、手机号搜索"
                    background="#EAEEF1"
                    @search="onSearch"
                    class="vantSearch"
            />
        </form>
        <van-list
                v-model="loading"
                :finished="finished"
                finished-text="没有更多了"
                @load="onLoad"
                offset="300"
                :error.sync="error"
                error-text="请求失败，点击重新加载"
                class="vantList"
        >
            <van-row type="flex" class="title_row">
                <van-col span="4"></van-col>
                <van-col span="6">房间号</van-col>
                <van-col span="7">客户名称</van-col>
                <van-col span="7">欠费金额(元)</van-col>
            </van-row>
            <van-cell v-for="item in list" is-link @click="detailClick(item)">
                <van-row type="flex" class="container_row">
                    <van-col span="3">
                        <van-icon name="friends-o" color="#1296DB" size="1.3rem"/>
                    </van-col>
                    <van-col span="7" class="van-ellipsis">{{item.roomAddr}}</van-col>
                    <van-col span="6" offset="1">{{item.linkOwnerName}}</van-col>
                    <van-col span="7" class="item_money">￥{{item.totalBillingMoney}}</van-col>
                </van-row>
            </van-cell>
        </van-list>
    </div>
</template>

<script>
    import {listArrearDetails} from "../../api/api.js"

    export default {
        props: ['projId'],
        data() {
            return {
                page: 1,
                rows: 10,
                list: [],
                loading: false,
                finished: false,
                keyword: '',
                totalMoney: '',
                total: 0,				// 数据总条数
                msg: '',
                mobile: '',
                routeFrom: '',                //来源路由
                arr: {}
            };
        },

        mounted() {
            this.keyword = this.$route.query.keyword;
            this.mobile = this.$route.query.mobile;
            this.routeFrom = this.$route.query.routeFrom;
        },
        methods: {
            // 获取列表数据方法
            async getList() {

                if (this.routeFrom != null && this.routeFrom == "moreSearch") {

                    this.arr = this.$route.query.searchMap
                    this.arr.mobile = this.mobile;
                    this.arr.page = this.page;
                    this.arr.rows = this.rows;

                } else {
                    let mobileTemp = this.mobile == null ? '' : this.mobile;
                    this.arr = {
                        projId: this.projId,
                        page: this.page,
                        rows: this.rows,
                        keyword: this.keyword,
                        mobile: mobileTemp
                    }
                }

                console.log(this.arr)

                let {data: res} = await listArrearDetails(this.arr);

                // 判断获取数据条数若等于0
                if (res.rows.length === 0) {
                    this.list = [];				// 清空数组
                    this.finished = true;		// 停止加载
                }

                // 若数据条数不等于0
                this.total = res.total;		// 给数据条数赋值
                this.list.push(...res.rows)	// 将数据放入list中

                this.loading = false;			// 加载状态结束
                // 如果list长度大于等于总数据条数,数据全部加载完成
                if (this.list.length >= res.total) {
                    this.finished = true;		// 结束加载状态
                }
            },

            // 被 @load调用的方法
            onLoad() {
                // 若加载条到了底部
                // 定时器仅针对本地数据渲染动画效果,项目中axios请求不需要定时器
                let timer = setTimeout(() => {
                    this.getList();					// 调用上面方法,请求数据
                    this.page++;					// 分页数加一
                    this.finished && clearTimeout(timer);//清除计时器
                }, 100);
            },

            onSearch(val) {
                //初始化参数,清空数组,重新载入
                this.keyword = val;
                this.page = 1;
                this.list = [];
                this.finished = false;
                this.loading = true;
                this.onLoad();
            },

            detailClick(item) {
                console.log(item)
                let itemArr = this.arr;
                itemArr.linkDataId = item.linkDataId;
                this.$router.push({
                    path: "/employee/detailsOfOwnersArrears",
                    query: {
                        keyword:itemArr.keyword,
                        linkDataId:item.linkDataId,
                        mobile:'',
                        page:'',
                        projId:'',
                        rows:''
                    }
                });
            }
        }
    };
</script>

<style scoped>
    .container {
        display: flex;
        flex-direction: column;
    }

    .vantSearch {
        position: absolute;
        width: 100%;
        z-index: 9999;
    }

    .vantList {
        margin-top: 50px;
    }

    .title_row {
        color: #123555;
        font-size: 1.2em;
    }

    .item_money {
        color: red;
    }

    .container_row {
        font-size: 1.2em;
    }

</style>
